import React from "react";


const Work = () => {
 
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
      
        <h1 className="primary-heading">Our Mission</h1>
        <p className="primary-text">
        HHIH is committed to providing innovative and empowering learning experiences for young people, helping them to develop the skills, 
        knowledge and confidence to pursue fulfilling lives and become engaged citizens.
        We also seek to provide mentorship and guidance to young people to help them make informed decisions
        about their future and to encourage them to pursue their goals.</p>

        <p></p>
        
        <h1 className="primary-heading">Our Vision</h1>
        <p className="primary-text">

        Our vision is to create a South Africa where young people can reach their full potential and
        contribute to the success of their community. 
        Through our efforts, we seek to create an environment of opportunity
        and inclusion in which young people can discover their passions and achieve their goals.
        </p>
      </div>
    
    </div>



  );
};

export default Work;