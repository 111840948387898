import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image2.jpg";


const About = () => {
  return (
    <div id="aboutDiv" className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-heading">About</p>
        
        <p className="primary-text">
        HHIH is a registered South African Non Profit Company which was founded in 2017. 
It is a level 1 B-BBEE Contributor, 100% black owned, and 90% female owned. 
We are a Public Benefit Organisation (PBO) aimed at youth development through education and skills development. 
We focus on the development of youth through subject choice initiatives, career guidance, skills enhancement, entrepreneurship programmes, and food & blanket drives.
 Our focus groups range from the ages of 13 years to 30 years.
        </p>
    
       
      </div>
    </div>
  );
};

export default About;