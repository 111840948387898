import React from "react";
const MyEmailSentModal = ({ visible, onClose }) => {
    if (!visible) return null;
  
    const handleEmailOnClose = (e) => {

        if (e.target.id === "container2"){
            onClose();
           } 
           else if (e.target.id === "btnCloseMessage"){
                onClose();
           }
    };
  
    return (
      <div
        id="container2"
        onClick={handleEmailOnClose}
        className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
      >
        <div className="bg-white p-4 rounded">
          <h1 className="font-semibold text-center text-xl text-gray-700">
            Email sent!
          </h1>
          
  
         
          <div className="text-center">
            <button id='btnCloseMessage' onClick={handleEmailOnClose} className="px-5 py-2 bg-gray-700 text-white rounded">
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  export default MyEmailSentModal;
  
