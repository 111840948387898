import React from "react";


export default function MyModal({visible, onClose, dataEntry}){

    const handleOnClose = (e) => {
      
       if (e.target.id === "container"){
        onClose();
       } 
       else if (e.target.id === "myButton"){
            onClose();
       }
       
      };
    
    if (!visible) return null;

    let myTitle="";
    let myReview="";
    let srcImage="";

    let counter=0;
    
    for(let i =0; i < dataEntry.length;++i)
      {
        if(dataEntry.charAt(i) === '{')
          {
            ++counter;

            
          }
          else
          {
            

            if(counter === 0)
              {
                myTitle = myTitle + dataEntry.charAt(i);
              }
              else if(counter === 1)
               {
                myReview = myReview + dataEntry.charAt(i);
               }
               else{
                srcImage = srcImage + dataEntry.charAt(i);
               }
          }
       
      }

  

    return(
        <div
        
        id='container'
        onClick={handleOnClose} 
        className="fixed inset-0 bg-black bg-opacity-30  backdrop-blur-sm flex justify-center items-center">

<div class=" my-10 max-w-sm flex flex-col m-8 rounded-lg overflow-auto bg-white shadow">
  
  <img class="h-56 w-full object-cover"
    src={srcImage}
     />
 
    
 
  <div class="flex-1 px-6 py-4 overflow-auto">
    <div class="font-bold text-xl mb-2">{myTitle}</div>
    <p class="text-gray-700 text-base">
        {myReview}
      </p>
  </div>
  

 
  <div class="px-6 py-4 bg-gray-100"
       
  >
    <button type="button"
    id='myButton'
    onClick={handleOnClose}
      class="bg-orange-600 hover:bg-blue-700 py-2 px-4 text-sm font-medium text-white border border-transparent rounded-lg focus:outline-none">Close</button>
  </div>
  
</div>

        </div>
    )
}