import React from 'react';
import pic1 from "../Assets/Donate-to-HW-1-q12wuobtsemb11tbyehj9ooie4ymlgzj5o8dac7f3c.jpg";


const Donate = () => {
  return (
    <div id="donateDiv" class="grid h-screen place-items-center">

       
        
        <h1 className="primary-heading">Donate</h1>
       
      
      <div
      className=" flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:max-w-xl md:flex-row">
      <img
        className="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        src={pic1}
        alt="" />
      <div className="flex flex-col justify-start p-6">
        <h5
          className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
          In Person Donations
        </h5>
        
        <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">Bank: FNB <br></br>Branch Code: 250655 <br></br> Account Name: Helping Hands Igniting Hope</p>
        
       
      </div>
    </div>
    </div>
  );
}
export default Donate;