import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import MyModal from "./Components/MyModal";
import Donate from "./Components/Donate";
import CarouselDefault from "./Components/GalleryCarousel";
import ContactUs  from "./Components/ContactUs";
import pic4 from "./Assets/24253763_1552047278209714_249919405310345216_n.jpg";
import pic2 from "./Assets/IMG-20240509-WA0019.jpg";
import pic3 from "./Assets/pick-meals-image2.jpg";
import pic1 from "./Assets/IMG-20240509-WA0005.jpg";
import pic5 from "./Assets/IMG-20240509-WA0023.jpg";
import pic6 from "./Assets/IMG-20240509-WA0015.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";

function App() {
  const settings = {
    accessibility:true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };


  let slides = [
    pic1,
    pic2,
    pic3,
    pic4,
  ];

  const data =[
    {
      name: 'Career Guidance',
      img: pic1,
      review: 'Organising career fairs and informational sessions to expose youth to various career paths and opportunities. Offering one-on-one counselling and guidance to help students make informed decisions about their future'
    },
    {
      name: 'Blanket Drive',
      img: pic5,
      review: 'We have donated over 400 blankets to the elderly, orphaned and the homeless. We promote and facilitate the establishment of youth-led initiatives and organisations that help young people become active participants in their communities.'
    },
    {
      name: 'Self Defence',
      img: pic6,
      review: 'We have hosted over 50 self-defence classes. We provide mentorship and guidance to young people to help them make informed decisions about their future and to encourage them to pursue their goals.'
    },
  ];

  

  const [ShowMymodal, setShowMyModal] = useState(false);
  const [defaultModalData, setModalData] = useState("");
 

  const updateModalValues = (title, paragraph, url) => {
    var final = title + "{" + paragraph + "{" + url;

    setModalData(final);
    //console.log(final);
  }

  const handleStartmodal = () => {
    
  }

  const handleOnClose = () => setShowMyModal(false);

  var divCounter =0;

  function increment(){
    divCounter = divCounter + 1;
 }

  return (
    <div className="App">
      <Home />
      <About />

   
      <Work />


      <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading"></p>
        <h1 className="primary-heading">Past Events</h1>
        </div>
        </div>
      
      
      <div id="eventsDiv" className='w-3/4 m-auto'>
    
      <div className="mt-20">
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.name} className="bg-white h-[450px] text-black rounded-xl  overflow-auto">
            <div className='h-56 bg-orange-500 flex justify-center items-center rounded-t-xl'>
              <img src={d.img} alt="" className="h-56 w-full object-cover"/>
            </div>
            
            <div id={divCounter} className="flex flex-col items-center justify-center gap-4 p-4">
              <p id='elementName' className="text-xl font-semibold">{d.name}</p>
              <p id='theReview' className="text-center">{d.review.substring(0,100)}...</p>
              {increment()}
              <button onClick={
                () => {
                  setShowMyModal(true);

                  var newName = document.activeElement.parentNode;
                  var newP = newName.children[0];

                  //console.log(newP.textContent);


                  console.log(newName.getAttribute('id'));

                  console.log(data[0].name);

                 // var myName = newName.children[0].textContent;
                   var myName = data[Number(newName.getAttribute('id'))].name;

                 // var myReview = newName.children[1].textContent;
                  var myReview = data[Number(newName.getAttribute('id'))].review;

                  var imageVar = newName.parentNode;
                   imageVar = imageVar.children[0];
                  imageVar = imageVar.children[0];

                  var picSource = imageVar.getAttribute('src');

                  var counter =0;
                  ++counter;
                  updateModalValues(myName,myReview,picSource);
                }

             

              }
              className='bg-orange-500 text-white text-lg px-6 py-1 rounded-xl'>Read More</button>
            </div>
          </div>
        ))}
      </Slider>
      </div>
      
    </div>


    <div className="work-section-wrapper">
      <div className="work-section-top">
       
        <h1 className="primary-heading">Gallery</h1>
     

        
        
        
      </div>
    
    </div>
    
      
      <CarouselDefault />
      
     
    

      <Donate />
      <ContactUs />
     
      
      <MyModal onClose={handleOnClose} visible={ShowMymodal} dataEntry={defaultModalData} />
      
      
    </div>
  );
}

export default App;